<template>
  <v-dialog v-model="show" scrollable content-class="v-dialog-mks">
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">Help</span>
        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text
        ><v-sheet>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, index) in helpcontent"
              v-bind:key="'e_' + index"
            >
              <v-expansion-panel-header>
                {{ item.header }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <img
                  style="width: auto"
                  v-if="item.imgsrc !== ''"
                  :elevation="5"
                  :src="getAsset(item.imgsrc)"
                />
                <v-sheet
                  v-if="item.text !== ''"
                  v-html="getHTML(item.text)"
                ></v-sheet>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  margin-left: 10px;
}
</style>

<script>
export default {
  name: "mkshelp",

  data: () => ({
    helpcontent: [
      {
        header: "Keyboard-Shortcuts",
        text: [
          { heading: "Table View" },
          { "Alt + W": "Set Focus to first Filter Field in Table Header" },
          { "Alt + C": "Clear Table Filter and reload Data" },
          { "Alt + R": "Reset Filter to initial Values in Table View" },
          { "Alt + O": "Clear Sort Order in Table View" },
          { "Alt + J": "Jump to Filter in Table Header" },
          { "Crtl + Click": "Mark/Unmark Row (stored until Page Reload)" },
          { "Page Down": "Jump to next 100 Items" },
          { "Page Up": "Jump to previous 100 Items" },
          { divider: "" },
          { heading: "Details View" },
          { "Alt + J": "Jump to Item Property" },
          { "Alt + S": "Save Changes to current Item" },
          { divider: "" },
          { heading: "Miscellaneous" },
          { "Alt + X": "Open left Navigation and start filtering for Lists" },
          { ESC: "Close topmost Dialog" },
        ],
        imgsrc: "",
      },
      {
        header: "Manage left Navigation",
        text: "Add a List to Favourites and change the sort order in Navigation Menu",
        imgsrc: "ManageNavigation",
      },
      // {
      //   header: "Fast jump to Item Properties with Alt + J",
      //   text: "",
      //   imgsrc: "FastJump",
      // },
      // {
      //   header: "Fast jump to Table Filter with Alt + J",
      //   text: "In Listview ALT + L will help to focus on the desired Filter Field in Table Header.",
      //   imgsrc: "",
      // },
      {
        header: "Change Field Order",
        text: "",
        imgsrc: "ChangeFieldOrder",
      },
      {
        header: "Fill in current Date with Double Click",
        text: "",
        imgsrc: "FillDate",
      },

      {
        header: "Copy different notations for a mac address into clipboard",
        text: "",
        imgsrc: "CopyMac",
      },
    ],
  }),

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    getAsset(imgsrc) {
      return require("@/assets/" + imgsrc + ".gif");
    },
    getHTML(content) {
      if (typeof content === "object") {
        // return Table
        var returnArray = [];
        returnArray.push("<table>");
        content.forEach(function (arrayItem) {
          returnArray.push("<tr class='text-start'>");
          for (const [key, value] of Object.entries(arrayItem)) {
            if (key === "divider") {
              returnArray.push("<td colspan=2><hr></td>");
            } else if (key === "heading") {
              returnArray.push("<th colspan=2 style='text-align: left;'>" + value + "</th>");
            } else {
              returnArray.push(
                "<th style='text-align: left;'>" +
                  key +
                  "</th><td>" +
                  value +
                  "</td>"
              );
            }
          }
          returnArray.push("</tr>");
        });
        returnArray.push("</table>");
        return returnArray.join("");
      } else {
        return content;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", "");
        }
      },
    },
  },
};
</script>