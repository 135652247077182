<template>
  <div>
    <v-container>
      <v-row>
        <v-col style="text-align: right">
          <v-btn-toggle dense v-model="viewtype" borderless group>
            <mksexpandingsearchfield
              placeholder="Filter by Resource"
              @searchChanged="searchChanged"
            >
            </mksexpandingsearchfield>
            <v-btn value="grid"><v-icon>grid_view</v-icon></v-btn>
            <v-btn value="list"><v-icon>view_list</v-icon></v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row v-if="!init && items.length === 0">
        <v-col>
          <v-alert type="info">No bundles found</v-alert>
        </v-col>
      </v-row>

      <v-row v-show="viewtype === 'grid'">
        <v-col
          v-for="(item, index) in items"
          :key="'i_' + index"
          class="col-sm-6 col-md-4 col-lg-3 col-xl-2 col-12"
        >
          <v-card @click="showDetails(item)">
            <!-- :style="'height: 200px; background: linear-gradient(rgba(1, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(' + getImgSrc(item) + ') center center / cover no-repeat'"
                 -->
            <v-img
              :ref="'img-' + item.ID_Resource_Bundle"
              :src="
                item.hasImage ? item.src : require('@/assets/DummyNetwBG2.jpg')
              "
              class="white--text align-start"
              gradient="to top, rgba(0,0,0,0), rgba(0,0,0,0.3), rgba(0,0,0,.6), rgba(0,0,0,.7), rgba(0,0,0,.7)"
              height="200px"
              @load="loadNextimage()"
              @error="loadNextimage()"
            >
              <v-card-title
                v-text="item.Bundle_Title"
                style="word-break: normal"
              >
              </v-card-title
            ></v-img>

            <v-card-text>
              <v-row>
                <v-col v-html="item.Description + '&nbsp;'"></v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="showDetails(item)"
                    ><v-icon>info_outline</v-icon></v-btn
                  >
                </template>
                <!-- <v-row>
                  <v-col>Min Period</v-col
                  ><v-col align="right">
                    <div
                      v-text="minutesToHoursAndMinutes(item['Min Period'])"
                    ></div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>Bundles</v-col
                  ><v-col align="right">{{ item["Bundles"] }}</v-col>
                </v-row>
                <v-row>
                  <v-col>Assets</v-col
                  ><v-col align="right">{{ item["Assets"] }}</v-col>
                </v-row>
                <v-row>
                  <v-col>People</v-col
                  ><v-col align="right">{{ item["People"] }}</v-col>
                </v-row>
                <v-row>
                  <v-col>Locations</v-col
                  ><v-col align="right">{{ item["Locations"] }}</v-col>
                </v-row> -->
                Click here for bundle details
              </v-tooltip>
              <v-spacer></v-spacer>
              
              <v-btn style="color:black;" 
            color="rgb(5, 218, 198)"
                @click.stop="showBooking(item)"
              >
                Book
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-data-table
        v-show="viewtype === 'list'"
        :items="items"
        fixed-header
        :height="tableheight"
        :headers="headers"
        item-key="ID_Resource_Bundle"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item="props">
          <tr @click="showDetails(props.item)">
            <template v-for="(header, index) in headers">
              <td :key="index" :class="header.alignRight ? 'text-right' : ''">
                {{ props.item[header.value] }}
              </td>
            </template>
          </tr>
        </template>
      </v-data-table>
    </v-container>
    <mksresourcebundledetails
      v-if="detailsVisible"
      :query="query"
      @close="hideBundle"
    ></mksresourcebundledetails>
    <mksresourcebundlebooked
      v-if="bookingVisible"
      :query="query"
      @close="hideBundle"
    ></mksresourcebundlebooked>
  </div>
</template>

<script>
import mksresourcebundledetails from "@/components/mksresourcebundle_details.vue";

export default {
  name: "mksresourcelist",

  components: {
    mksresourcebundledetails,
  },

  data: () => ({
    viewtype: "grid",
    items: [],
    itemsWithImage: [],
    itemsWithImageCounter: 1,
    headers: [],
    tableheight: 500,
    container: null,
    showCalendar: false,
    detailsVisible: false,
    bookingVisible: false,
    query: {},
    filterValue: "",
    loading: false,
    init: true,
  }),

  props: {
    selectedNavEntry: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  created() {
    this.getBundlesList();
  },

  methods: {
    showBooking(item) {
      this.query = {
        id: item.ID_Resource_Bundle,
      };
      // this.showCalendar = true;
      this.bookingVisible = true;
    },
    showDetails(item) {
      this.query = {
        id: item.ID_Resource_Bundle,
        filterValue: this.filterValue,
      };
      // this.showCalendar = true;
      this.detailsVisible = true;
    },
    minutesToHoursAndMinutes(minutes) {
      var hours = 0;
      var remainingMinutes = 0;

      hours = Math.floor(minutes / 60);
      remainingMinutes = minutes % 60;
      return (
        (hours.toString() < 10 ? "0" : "") +
        hours +
        ":" +
        (remainingMinutes.toString() < 10 ? "0" : "") +
        remainingMinutes
      );
    },
    getImgSrc(item) {
      if (item.hasImage) {
        var d = new Date();
        return (
          "/api/imgFRB?Id=" + item.ID_Resource_Bundle + "&v=" + d.getTime()
        );
      } else {
        return "require('@/assets/DummyNetwBG2.jpg')";
      }
    },
    columnalign(stype) {
      return stype === "Double" || stype === "Int32";
    },
    searchChanged(filterValue) {
      this.filterValue = filterValue;
      this.getBundlesList();
    },
    getBundlesList() {
      // var tempthis = this;

      if (this.loading) {
        return;
      }

      this.itemsWithImageCounter = 0;

      this.$http
        .post("api/list", {
          id: this.selectedNavEntry.filterId,
          filter: this.filterValue,
        })
        .then((response) => {
          this.items = response.data.recordsets[0].data;
          this.init = false;
          this.headers = [];
          this.visibleProps = [];
          var tmpHeader = null;

          for (var cnt = 0; cnt < response.data.recordsets[2].length; cnt++) {
            if (
              response.data.recordsets[2][cnt].substr(0, 3).toLowerCase() !==
              "id_"
            ) {
              tmpHeader = {
                text: response.data.recordsets[2][cnt].replace(/_/g, " "),
                value: response.data.recordsets[2][cnt],
                type: response.data.recordsets[0].columns[cnt][
                  response.data.recordsets[2][cnt]
                ],
                alignRight: this.columnalign(
                  response.data.recordsets[0].columns[cnt][
                    response.data.recordsets[2][cnt]
                  ]
                ),
                isphonenr:
                  response.data.recordsets[2][cnt].indexOf("ufnummer") > -1 ||
                  response.data.recordsets[2][cnt] === "Telefonnummer",
              };

              if (
                tmpHeader.value !== "Description" &&
                tmpHeader.value !== "_bgcolor" &&
                tmpHeader.value !== "src" &&
                tmpHeader.value !== "hasImage"
              ) {
                if (tmpHeader.value == "Description_plain_text") {
                  tmpHeader.text = "Description";
                } else if (tmpHeader.value == "URL_for_further_information") {
                  tmpHeader.text = "Wiki";
                }
                this.headers.push(tmpHeader);
              }
            }
          }

          this.$nextTick(() => {
            this.itemsWithImage = this.items.filter((i) => i.hasImage);

            if (this.itemsWithImage.length > 0) {
              this.itemsWithImage[0].src = this.getImgSrc(
                this.itemsWithImage[0]
              );
            }
          });

          this.$nextTick(function () {
            this.container = document.getElementsByClassName("v-main__wrap")[0];

            // zeitverzögerte Ausführung um richtige Höhe für container zu erhalten
            this.updateTableHeight();
            window.addEventListener("resize", this.updateTableHeight);
          });
        })
        .catch(() => {
          this.$showMessage({
            content: "Could not load data. Please try again later.",
            color: "error",
            timeout: 3000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadNextimage() {
      if (this.itemsWithImageCounter < this.itemsWithImage.length) {
        this.itemsWithImage[this.itemsWithImageCounter].src = this.getImgSrc(
          this.itemsWithImage[this.itemsWithImageCounter]
        );
        this.itemsWithImageCounter++;
      }
    },

    updateTableHeight() {
      this.tableheight = this.container.offsetHeight - 84 - 12;
    },

    hideCalendar() {
      this.showCalendar = false;
    },
    hideBundle() {
      this.detailsVisible = false;
      this.bookingVisible = false;
    },
  },

  watch: {
    selectedNavEntry: {
      handler() {
        this.getBundlesList();
      },

      deep: true,
    },
  },
};
</script>
