<template>
    <table>
        <tr>
            <td colspan="3" style="font-size: 12px;">
                {{ label }}
            </td>
        </tr>
        <tr>
            <td>
                <v-select ref="hour" menu-props="auto" :disabled="disabled" @input="sendChange" :items="hours"
                    v-model="hoursval" :rules="[...requiredRule]"
                    :style="elemError != '' ? 'background-color:#995400;' : ''"></v-select>
            </td>
            <td>:</td>
            <td>
                <v-select menu-props="auto" :disabled="disabled" @input="sendChange" :items="minutes"
                    v-model="minutesval" :rules="[...requiredRule]"
                    :style="elemError != '' ? 'background-color:#995400;' : ''"></v-select>
            </td>
        </tr>
        <tr v-if="elemError != ''">
            <td colspan="3" style="font-size: 10px;">
                {{ elemError }}
            </td>
        </tr>
    </table>
</template>

<style scoped>
.v-input {
    width: 3.5em;
}
</style>

<script>

export default {
    name: "mksslottimeonly",

    components: {
    },

    data: () => ({
        origval: null,
        // timeonly: '',
        hours: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
        // minutes: ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
        minutes: ["00", "15", "30", "45"],
        hoursval: "00",
        minutesval: "00"
    }),

    props: {
        timeonlyval: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            required: true,
            default: () => (false),
        },
        disabled: {
            type: Boolean,
            required: true,
            default: () => (false),
        },

        elemError: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {

        requiredRule: {
            get() {
                if (this.required) {
                    return [
                        (v) =>
                            !!v || "is required",
                    ];
                }
                else {
                    return [];
                }
            },
        }

    },
    methods: {
        sendChange() {
            var time = new Date('1970-01-01T' + this.hoursval + ":" + this.minutesval + ':00');
            if (isNaN(time) === false) {
                this.$emit("changed", this.hoursval + ":" + this.minutesval);
            }
        }

    },
    watch: {

        timeonlyval: {
            handler() {
                if (this.timeonlyval && this.timeonlyval.length > 4) {
                    this.$nextTick(() => {
                        this.hoursval = this.timeonlyval.toString().substr(0, 2);
                        this.minutesval = this.timeonlyval.toString().substr(3, 2);
                    });
                }
            }
        },
    }

};
</script>