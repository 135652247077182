<template>
    <div style="max-width: 300px;float: right;">
        <v-text-field v-model="search" @focus="setClosed(false)" @blur="setClosed(true)" @keyup="keyup($event)" filled
            dense prepend-inner-icon="search" :placeholder="placeholder" class="expanding-search"
            :class="{ 'closed': searchClosed && !search }" clearable
            @click:clear="search = null;sendChange()"></v-text-field>

    </div>
</template>

<style scoped>
.closed {
    max-width: 45px;
}
</style>


<style lang="sass">
.v-input.expanding-search
    height: 36px !important
    overflow: hidden
    margin-top: 4px
    margin-right: 4px
    .v-input__control
        .v-input__slot
            &:before, &:after
                border-color: transparent !important
        .v-input__slot
        background: transparent !important

</style>

<script>
import _ from "lodash";

export default {
    name: "mksexpandingsearchfield",

    components: {
    },

    data: () => ({
        searchClosed: true,
        search: null
    }),

    props: {
        placeholder: {
            type: String,
            required: true,
            default: "Search",
        },
    },

    methods: {
        setClosed(isClosed) {
            this.searchClosed = isClosed;
        },
        throttledMethod: _.debounce(function () {
            this.sendChange();
        }, 300),
        sendChange() {
            this.$emit('searchChanged', this.search);
        },
        keyup(event) {
            if (
                !event.altKey &&
                (event.keyCode > 44 || event.keyCode === 32 || event.keyCode === 8)
            ) {
                this.throttledMethod();
            }
        },
    }
}
</script>