<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    <v-row>
      <v-col>{{ message }}</v-col>
      <v-col cols="2"><v-btn icon @click="show = false"><v-icon>close</v-icon></v-btn></v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 4000,
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbarShowMessage") {
        this.message = state.snackbarContent;
        this.color = state.snackbarColor;
        this.timeout = state.timeout ? state.timeout : 4000;
        this.show = true;
      }
    });
  },
};
</script>