<template>
    <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-x
        max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-model="dateFormatted1" ref="txtfld" label="" placeholder="DD.MM.YYYY" persistent-hint
                prepend-inner-icon="event" @dblclick.native="setToday(1)" @input="onClearClicked()" v-on="on" clearable
                :append-outer-icon="elemError !== '' ? 'warning' : undefined" :hint="elemError"
                :style="elemError != '' ? 'background-color:#995400;' : ''" :disabled="disabled">
            </v-text-field>
        </template>
        <v-date-picker v-model="date1" no-title @input="menu1 = false; emitUpdateDate();" locale="de-de"
            first-day-of-week="1"></v-date-picker>
    </v-menu>
</template>

<script>

export default {
    name: "mksdatesimple",

    data: () => ({
        date1: null,
        dateFormatted1: null,
        menu1: false,
        dontEmit: false,
    }),

    props: {
        date: null,
        fieldname: {
            type: String,
            required: true,
        },
        elemError: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        onClearClicked() {

            this.date1 = this.parseDate(this.dateFormatted1);
            this.emitUpdateDate();
        },
        emitUpdateDate() {
            this.$emit('updatedate', { date: (this.date1 ? this.date1 : "0001-01-01") + "T00:00:00", fieldname: this.fieldname });
        },

        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("T")[0].split(" ")[0].split("-");
            return (
                `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}` +
                (date.split(" ").length > 1 ? " " + date.split(" ")[1] : "")
            );
        },
        parseDate(date) {
            if (!date) return null;

            try {
                const [day, month, year] = date.split("T")[0].split(" ")[0].split(".");
                return (
                    `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}` +
                    (date.split(" ").length > 1 ? " " + date.split(" ")[1] : "")
                );
            } catch {
                return "";
            }
        },
        setToday() {
            var td = new Date();
            var dateString =
                td.getFullYear() +
                "-" +
                String(td.getMonth() + 1).padStart(2, "0") +
                "-" +
                String(td.getDate()).padStart(2, "0");

            this.date1 = dateString;
        },
    },

    mounted() {
        this.dontEmit = true;
    },

    computed: {
        dateToReturnObject() {
            if (this.date1) {
                const [year, month, day] = this.date1.split(" ")[0].split("-");
                return {
                    "Year": year,
                    "Month": month,
                    "Day": day,
                }
            }
            else {
                return {
                    "Year": 0,
                    "Month": 0,
                    "Day": 0,
                }
            }
        },

    },

    watch: {
        date1(newval, oldval) {
            this.dateFormatted1 = this.formatDate(this.date1);
            if (newval !== oldval) {
                this.$emit('updatedate', { date: (this.date1 ? this.date1 : "0001-01-01") + "T00:00:00", fieldname: this.fieldname });
            }

        },

        date() {

            if (this.date && this.date1 !== this.date.replace("T00:00:00", "")) {
                if (this.date == "0001-01-01T00:00:00") {
                    this.date1 = null;
                }
                else {
                    this.date1 = (this.date ? this.date.replace("T00:00:00", "") : this.date);

                }
            }

        },
    }
}
</script>