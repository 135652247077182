<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
      style="position: relative"
    >
      <div id="movingText" ref="movingText">{{ tmpTime }}</div>
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Details
          </span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text ref="outer">
          <v-container ref="inner">
            <template>
              <div ref="jumpdummytop"></div>
            </template>
            <template v-if="showLoadingCircle && !resetting">
              <v-row align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="5">
                  <v-row>
                    <v-col
                      ><v-text-field
                        outlined
                        dense
                        ref="bundleTitle"
                        v-model="bundle.details.bundle_title"
                        label="Bundle title"
                        flat
                        hide-details
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-if="!bundle.details.interchangeable" cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            v-on="on"
                            v-model="
                              bundle.details
                                .smallest_possible_booking_period_in_minutes
                            "
                            :items="bookingperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Smallest period"
                            readonly
                          ></v-select>
                        </template>
                        <span
                          >smallest possible booking period in hours and
                          minutes</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col v-if="!bundle.details.interchangeable" cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            v-on="on"
                            v-model="bundle.details.setup_time_in_minutes"
                            :items="setupperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Setup"
                            readonly
                          ></v-select>
                        </template>
                        <span>Setup time in hours and minutes</span>
                      </v-tooltip>
                    </v-col>

                    <v-col v-if="!bundle.details.interchangeable" cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            v-on="on"
                            v-model="bundle.details.teardown_time_in_minutes"
                            :items="setupperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Teardown"
                            readonly
                          ></v-select>
                        </template>
                        <span>Teardown time in hours and minutes</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      v-if="!bundle.details.interchangeable"
                      cols="3"
                      :style="
                        'padding: 7px;' +
                        (bundle.details
                          .smallest_possible_booking_period_in_minutes -
                          bundle.details.setup_time_in_minutes -
                          bundle.details.teardown_time_in_minutes <
                        0
                          ? 'color:red;'
                          : '')
                      "
                    >
                      =
                      {{
                        bundle.details
                          .smallest_possible_booking_period_in_minutes -
                        bundle.details.setup_time_in_minutes -
                        bundle.details.teardown_time_in_minutes
                      }}
                      min <br /><span style="font-size: smaller"
                        >effective usage</span
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="bundle.details.url_for_further_information">
                    <v-col>
                      <v-btn
                        color="rgb(5, 218, 198)"
                        text
                        @click="
                          windowObject.open(
                            bundle.details.url_for_further_information
                          )
                        "
                        >Click here for further information ({{
                          extractDomainAndCountry(
                            bundle.details.url_for_further_information
                          )
                        }})</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="4">
                  <div
                    class="v-input v-textarea v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--is-readonly v-input--dense theme--dark v-text-field v-text-field--solo-flat v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
                  >
                    <div class="v-input__control">
                      <div class="v-input__slot" style="height: 104px">
                        <fieldset aria-hidden="true">
                          <legend style="width: 66.75px">
                            <span class="notranslate">&ZeroWidthSpace;</span>
                          </legend>
                        </fieldset>
                        <div class="v-text-field__slot">
                          <label
                            for="input-457"
                            class="v-label v-label--active theme--dark"
                            style="left: 0px; right: auto; position: absolute"
                            >Description</label
                          >
                          <div v-html="bundle.details.description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="3">
                  <v-select
                    label="Test Types"
                    ref="testtypes"
                    height="104px"
                    v-model="bundle.details.testtypes"
                    :items="testtypes"
                    item-text="TT"
                    :item-value="'TT'"
                    dense
                    multiple
                    hide-details
                    outlined
                    readonly
                    :append-icon="undefined"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="margin-top: 0px">
                  <hr />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-toolbar flat>
                    <v-row>
                      <v-col class="text-right">
                        <v-text-field
                          outlined
                          dense
                          v-model="search"
                          label="Filter for components"
                          flat
                          hide-details
                          clearable
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-row>
                    <v-col cols="12">
                      <div
                        style="
                          overflow-x: auto;
                          overflow-y: auto;
                          height: 300px;
                        "
                      >
                        <v-treeview
                          ref="itemtree"
                          v-model="selected"
                          :open.sync="open"
                          :items="bundle.leaves"
                          :search="search"
                          :filter="filter"
                          :open-all="expanded"
                          hoverable
                          item-key="id_rd"
                          dense
                        >
                          <template v-slot:label="{ item }">
                            <div
                              @contextmenu.prevent="showToolsmenu($event, item)"
                              
                              class="ox"
                            >
                              <v-icon
                                v-if="item.level % 2 === 1 || item.level === 0"
                                v-text="item.icon"
                              >
                              </v-icon
                              >&nbsp;
                              <span
                                :style="
                                  item.deleteLeaf
                                    ? 'background-color: #f0a13a;'
                                    : item.newLeaf
                                    ? 'background-color: #9be695;'
                                    : ''
                                "
                                v-html="
                                  $highlight(
                                    item.name,
                                    search,
                                    'indigo lighten-3'
                                  ) +
                                  (item.icon !== null &&
                                  item.children &&
                                  item.level % 2 === 1 &&
                                  item.level > 0
                                    ? ' (' + item.children.length + ')'
                                    : '')
                                "
                              >
                              </span>
                            </div>
                          </template>
                          <template v-slot:append="{ item }">
                            <v-tooltip right open-delay="700" close-delay="500">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  v-show="item.hasAS"
                                  @click="showAvailabilitySlots(item)"
                                  >date_range</v-icon
                                >
                              </template>
                              <span>change availability slots</span>
                            </v-tooltip>

                            <v-tooltip right open-delay="700" close-delay="500">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  class="custom-icon-color"
                                  v-show="
                                    !item.hasAS &&
                                    item.children &&
                                    item.level % 2 === 1 &&
                                    item.children.filter(
                                      (c) => c.hasAS === true
                                    ).length
                                  "
                                  >date_range</v-icon
                                >
                              </template>
                              <span
                                >contains resource(s) with deviating time
                                slots</span
                              >
                            </v-tooltip>
                          </template>
                        </v-treeview>
                      </div>
                    </v-col></v-row
                  >
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="rgb(5, 218, 198)"
            text
            @click.stop="show = false"
          >
            Close
          </v-btn>
          
          <v-btn style="color:black; margin-left: 30px" large
            color="rgb(5, 218, 198)"
            
            @click.stop="bookingVisible = true"
          >
            Book
          </v-btn>
          </v-card-actions
        >
      </v-card>
    </v-dialog>
    <mksresourcebundlebooked
      v-if="bookingVisible"
      :query="query"
      @close="hideBundle"
    ></mksresourcebundlebooked>
  </div>
</template>

<style scoped>
.custom-icon-color {
  opacity: 0.5;
}

#movingText {
  position: absolute;
  font-size: 18px;
  color: #000;
  white-space: nowrap;
  z-index: 5000;
}

div.ox {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: dark;
}

div >>> .v-select__slot > .v-input__append-inner {
  display: none;
}

div >>> .v-select--is-multi {
  cursor: default;
}

div >>> .v-select--is-multi > .v-select__selections {
  cursor: default;
}
</style>

<style>
.pulse {
  margin: 100px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 70%;
  background: #7fd679;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(79, 204, 44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
    box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
    box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
  }
}
</style>

<script>
import { mapState } from "vuex";
export default {
  name: "mksresourcebundle_details",

  components: {},

  data: () => ({
    saving: false,
    bookingVisible: false,
    hintOnClose: "You have unsaved changes!",
    showReservationEvents: true,
    newstartdate: null,
    newstarttime: null,
    selectedElement: null,
    selectedOpen: false,
    selectedSlot: {},
    headlineclass: "",
    bgcolor: "",
    loading: false,
    showLoadingCircle: false,
    firstcall: false,
    bundle: {
      details: {
        id_resource_bundle: null,
        bundle_title: null,
        description: null,
        id_resource_root: null,
      },
      leaves: [],
    },
    selected: [],
    initiallyOpen: [],
    open: [],

    confirmModeOn: false,
    x: 0,
    y: 0,
    menuOpen: [],
    search: null,
    searchForAdd: null,
    caseSensitive: false,
    expanded: false,
    leavesForDeletion: [],
    newLeaves: [],
    parentObj: null,
    showAddResource: false,
    addRessourceHeadline: "",
    searchlabel: "Search for components",
    AlternativeDetailsLayout: false,
    IconButtons: false,
    SaveAndClose: false,
    currentItem: { id: 0 },
    global_unique_id_rd: 0,
    grphsh: "",
    newChildItem: null,
    testtypes: [],
    bundleOrig: null,
    showDeleteBundleConfirm: false,
    confirmDialogShow: false,
    selectOtherDialogShow: false,
    otherBundleId: 0,
    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "stack",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    // events: [],
    imagefile: null,
    bundleimagesource: null,
    bundleimageerror: false,
    imagechanged: false,
    dragover: false,
    deleteImage: false,
    tmpTime: "",
    minuteSteps: 15,
    calendarSettings: {
      showBookable: true,
      showInvalid: false,
      showReservationEventsForThisBundle: true,
      showReservationEventsForOtherBundles: false,
    },
    calendarSettingsOrig: null,
  }),

  props: {
    query: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  mounted() {
    this.search = this.query.filterValue;
    this.getDetails();
  },

  methods: {
    hideBundle() {
      this.bookingVisible = false;
    },
    extractDomainAndCountry(urlString) {
      try {
        const url = new URL(urlString);
        const domain = url.hostname;

        return domain;
      } catch (error) {
        return "Invalid URL";
      }
    },

    addLeadingZero(number) {
      return number < 10 ? "0" + number : number;
    },

    scrollToItem(index) {
      if (this.$refs.listItemRefs && this.$refs.listItemRefs[index]) {
        const listItemElement = this.$refs.listItemRefs[index].$el;
        const containerElement = this.$refs.listContainer;

        if (listItemElement && containerElement) {
          const listItemRect = listItemElement.getBoundingClientRect();
          const containerRect = containerElement.getBoundingClientRect();

          if (
            listItemRect.top < containerRect.top ||
            listItemRect.bottom > containerRect.bottom
          ) {
            listItemElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }
    },

    openBranch(item) {
      this.open.push(item.id_rd);
      item.children.forEach((child) => this.openBranch(child));
    },
    closeBranch(item) {
      var found = this.open.indexOf(item.id_rd);
      if (found > -1) {
        this.open.splice(found, 1);
      }
      item.children.forEach((child) => this.closeBranch(child));
    },
    getDetails(showloading = false, reservationsonly = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (showloading) {
        this.showLoadingCircle = true;
      }
      var tempthis = this;

      this.$http
        .post("api/ResourceBundleGet", {
          id: this.query.id,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            if (reservationsonly) {
              this.bundle.TimeSlotsBookedAsEventList =
                response.data.bundle.TimeSlotsBookedAsEventList;
            } else {
              this.bundle.hasChanges = false;
              this.bundle = response.data.bundle;

              if (
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === null ||
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === 0
              ) {
                this.bundle.details.smallest_possible_booking_period_in_minutes = 60;
              }

              this.bundleOrig = JSON.parse(JSON.stringify(this.bundle.details));

              if (this.open.length === 0) {
                this.open = [];
                this.open.push(tempthis.bundle.leaves[0].id_rd);
              }

              this.testtypes = this.bundle.details.testtypes;

              this.global_unique_id_rd = response.data.bundle.globunique_id;
            }

            this.bundleimageerror = false;
            this.bundleimagesource = this.getImgSrc(this.bundle.details);
          } else {
            this.show = false;
            this.$showMessage({
              content: "bundle not found",
              color: "warning",
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    getImgSrc(item) {
      if (item.hasImage) {
        this.bundleimageerror = false;
        var d = new Date();
        return (
          "/api/imgFRB?Id=" + item.id_resource_bundle + "&v=" + d.getTime()
        );
      } else {
        this.bundleimageerror = true;
        return null;
      }
    },

    refreshSettings() {
      this.AlternativeDetailsLayout = this.$getPref({
        TabName: "Options",
        PrefID: "AlternativeDetailsLayout",
      });
      this.IconButtons = this.$getPref({
        TabName: "Options",
        PrefID: "IconButtons",
      });
      if (this.AlternativeDetailsLayout == null) {
        this.AlternativeDetailsLayout = false;
      }
      this.SaveAndClose = this.$getPref({
        TabName: "Options",
        PrefID: "SaveAndClose",
      });
      if (this.SaveAndClose == null) {
        this.SaveAndClose = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }
    },

    getGroupType(type) {
      return (type + (type.startsWith("Asset") ? "" : "s")).replace(
        "Persons",
        "People"
      );
    },
  },

  watch: {
    search: {
      handler() {
        if (this.search !== "" && this.initiallyOpen.length === 0) {
          this.initiallyOpen = this.open;
        } else if (this.search === "" && this.initiallyOpen.length > 0) {
          this.open = this.initiallyOpen;
          this.initiallyOpen = [];
          return;
        }

        this.open =
          this.search !== "" ? Object.keys(this.$refs.itemtree.nodes) : [];
      },
    },
    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },

    newChildItem(newValue) {
      if (newValue !== null) {
        this.scrollToItem(newValue);
      }
    },
    lastKeyUp: {
      handler() {
        if (this.show) {
          if (this.lastKeyUp.altKey) {
            //check if dialog is topmost
            var dia2 = this.$getHighest(".v-dialog--active");
            dia2 = dia2 && dia2.querySelector("[dialogid]");
            if (
              dia2 &&
              parseInt(dia2.getAttribute("dialogid")) === this.$el.__vue__._uid
            ) {
              switch (this.lastKeyUp.keyCode) {
                case 83: // Alt + S = Save
                  this.saveBundle();
                  break;
                case 171: // Alt + Plus Sign
                  this.btnGetgetSuggestions();
                  break;
              }
            }
          } else if (this.showAddResource) {
            const pagesize = 10;
            switch (this.lastKeyUp.keyCode) {
              case 36: // pos1
                this.newChildItem = 0;
                break;
              case 38: // up
                if (this.newChildItem > 0) {
                  this.newChildItem--;
                }
                break;
              case 40: // down
                if (this.newChildItem < this.newLeaves.length - 1) {
                  this.newChildItem++;
                }
                break;
              case 35: // end
                this.newChildItem = this.newLeaves.length - 1;
                break;
              case 34: // page down
                if (this.newChildItem + pagesize <= this.newLeaves.length - 1) {
                  this.newChildItem += pagesize;
                } else {
                  this.newChildItem = this.newLeaves.length - 1;
                }
                break;
              case 33: // page up
                if (this.newChildItem - pagesize >= 0) {
                  this.newChildItem -= pagesize;
                } else {
                  this.newChildItem = 0;
                }
                break;
              case 13:
                this.addChild(this.newLeaves[this.newChildItem]);
                break;
            }
          }
        }
      },
    },
  },
  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),
    smallestPeriodString() {
      return (
        this.bundle.details.smallest_possible_booking_period_in_minutes + " min"
      );
    },
    windowObject() {
      return window;
    },
    prefs() {
      return this.currentUser && this.currentUser.prefs
        ? this.currentUser.prefs
        : null;
    },
    bookingperiods() {
      var b = [];
      var hours = 0;
      var remainingMinutes = 0;
      for (let minutes = 15; minutes < 1440; minutes += 15) {
        hours = Math.floor(minutes / 60);
        remainingMinutes = minutes % 60;
        b.push({
          v: minutes,
          t:
            (hours.toString() < 10 ? "0" : "") +
            hours +
            ":" +
            (remainingMinutes.toString() < 10 ? "0" : "") +
            remainingMinutes,
        });
      }
      return b;
    },
    setupperiods() {
      var b = [];
      var hours = 0;
      var remainingMinutes = 0;
      for (let minutes = 0; minutes < 1440; minutes += 15) {
        hours = Math.floor(minutes / 60);
        remainingMinutes = minutes % 60;
        b.push({
          v: minutes,
          t:
            (hours.toString() < 10 ? "0" : "") +
            hours +
            ":" +
            (remainingMinutes.toString() < 10 ? "0" : "") +
            remainingMinutes,
        });
      }
      return b;
    },
    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          this.bundle.leaves = [];
          this.$emit("close");
        }
      },
    },

    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    hasChildren() {
      return (
        this.bundle.leaves &&
        this.bundle.leaves.length > 0 &&
        this.bundle.leaves[0].children &&
        this.bundle.leaves[0].children.length > 0
      );
    },
  },
};
</script>
