export default {

    install(Vue) {


        Vue.prototype.$getPref = function (pref) {
            // {
            //     TabName: tabName,
            //     PrefID: prefID,
            //     Value: value,
            //     Save: save
            //   }
            if (pref && pref.TabName && pref.PrefID) {
                // let returnval = this.$store.state.currentUser.prefs.hasOwnProperty() &&
                //     this.$store.state.currentUser.prefs[pref.TabName].find && 
                //     this.$store.state.currentUser.prefs[pref.TabName].find(x => x.ID === pref.PrefID)

                var returnval = null;

                if (typeof (this.$store.state.currentUser.prefs) !== 'undefined' && this.$store.state.currentUser.prefs[pref.TabName]) {
                    if (this.$store.state.currentUser.prefs[pref.TabName].find) {
                        returnval = this.$store.state.currentUser.prefs[pref.TabName].find(x => x.ID === pref.PrefID);
                    }
                    else if (this.$store.state.currentUser.prefs[pref.TabName].ID && this.$store.state.currentUser.prefs[pref.TabName].ID == pref.PrefID) {
                        returnval = { Value: this.$store.state.currentUser.prefs[pref.TabName].Value };
                    }
                }

                if (returnval && returnval.Value) {
                    if (typeof returnval.Value === "string") {
                        if (returnval.Value.toString().toLowerCase() === "true") {
                            return true
                        }
                        else if (returnval.Value.toString().toLowerCase() === "false") {
                            return false
                        }
                        else {
                            return returnval.Value
                        }
                    }
                    else {
                        return returnval.Value
                    }
                }
                else {
                    return null
                }
            }
            else {
                return null
            }

        }

        Vue.prototype.$setPref = function (pref) {
            pref.Value = !pref.Value || pref.Value === null ? '' : pref.Value.toString()
            return this.$http
                .post("api/setPref", pref)
                .then(response => {
                    if (response.data.error) {
                        throw response.data.error
                    } else {
                        const currentUser = response.data
                        currentUser.prefs = this.$xmlToJson((new DOMParser()).parseFromString(currentUser.prefs, "text/xml")).Prefs

                        this.$store.dispatch("setUser", currentUser)
                    }
                })
                .catch(e => {
                    var message =
                        e.response && e.response.data
                            ? e.response.data
                            : e.message
                                ? e.message
                                : e;
                    this.$showMessage({ content: message, color: "error" });
                });
        }

        Vue.prototype.$xmlToJson = function (xml) {
            var xmlToJson = function (xml) {
                // Create the return object
                var obj = {};

                if (xml.nodeType == 1) {
                    // element
                    // do attributes
                    if (xml.attributes.length > 0) {
                        obj["@attributes"] = {};
                        for (var j = 0; j < xml.attributes.length; j++) {
                            var attribute = xml.attributes.item(j);
                            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
                        }
                    }
                } else if (xml.nodeType == 3) {
                    // text
                    obj = xml.nodeValue;
                }

                // do children
                // If all text nodes inside, get concatenated text from them.
                var textNodes = [].slice.call(xml.childNodes).filter(function (node) {
                    return node.nodeType === 3;
                });
                if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
                    obj = [].slice.call(xml.childNodes).reduce(function (text, node) {
                        return text + node.nodeValue;
                    }, "");
                } else if (xml.hasChildNodes()) {
                    for (var i = 0; i < xml.childNodes.length; i++) {
                        var item = xml.childNodes.item(i);
                        var nodeName = item.nodeName;
                        if (nodeName === "#text") {
                            continue;
                        }
                        if (typeof obj[nodeName] == "undefined") {
                            obj[nodeName] = xmlToJson(item);
                        } else {
                            if (typeof obj[nodeName].push == "undefined") {
                                var old = obj[nodeName];
                                obj[nodeName] = [];
                                obj[nodeName].push(old);
                            }
                            obj[nodeName].push(xmlToJson(item));
                        }
                    }
                }
                return obj;
            }

            return xmlToJson(xml)
        }

        Vue.prototype.$refreshUser = function () {
            return this.$http
                .get("api/userRefresh")
                .then(response => {
                    if (response.data.error) {
                        throw response.data.error
                    } else {
                        const currentUser = response.data
                        currentUser.prefs = this.$xmlToJson((new DOMParser()).parseFromString(currentUser.prefs, "text/xml")).Prefs

                        return this.$store.commit("setUser", currentUser)
                    }
                }).catch(e => {
                    var message =
                        e.response && e.response.data
                            ? e.response.data
                            : e.message
                                ? e.message
                                : e;
                    this.$showMessage({ content: message, color: "error" });
                });
        }

        Vue.prototype.$showPreview = function
        (previewdata) {
        this.$store.commit('setpreviewdata', previewdata)
    }

        Vue.prototype.$showMessage = function
            ({ content = '', color = '', timeout = 4000 }) {
            this.$store.commit('snackbarShowMessage', { content, color, timeout })
        }

        Vue.prototype.$setSelectedItems = function
            ({ id_query = 0, id = 0, isSelected = false }) {
            this.$store.commit('setSelectedItems', { id_query, id, isSelected })
        }

        Vue.prototype.$getUrlParams = function
            () {
            return this.$store.getters.getUrlParams;
        }

        Vue.prototype.$setCurrentDialog = function (currentDialog) {
            this.$store.dispatch("setCurrentDialog", currentDialog)
        }

        Vue.prototype.$setUrlParams = function (urlParams) {
            this.$store.dispatch("setUrlParams", urlParams)
        }

        var getZIndex = function (el) {
            // Return the z-index of el, or 0 if none is set.
            return parseInt(getCssProperty(el, "z-index")) || 0;
        }

        var getCssProperty = function (el, prop) {
            // Return the computed value of the css property prop for the element el
            return document.defaultView.getComputedStyle(el, null).getPropertyValue(prop);
        }

        Vue.prototype.$getHighest = function (selector) {
            // Return the element that matches selector having the largest z index
            var els = document.querySelectorAll(selector);
            if (els.length > 0) {
                return Array.from(els).reduce((a, b) =>
                    getZIndex(a) >= getZIndex(b) ? a : b
                );
            } else {
                return null;
            }
        }

        function LightenDarkenColor(col, amt) {

            var usePound = false;

            if (col[0] == "#") {
                col = col.slice(1);
                usePound = true;
            }

            var num = parseInt(col, 16);

            var r = (num >> 16) + amt;

            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            var b = ((num >> 8) & 0x00FF) + amt;

            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            var g = (num & 0x0000FF) + amt;

            if (g > 255) g = 255;
            else if (g < 0) g = 0;

            var string = "000000" + (g | (b << 8) | (r << 16)).toString(16);
            return (usePound ? "#" : "") + string.substring(string.length - 6);

        }

        Vue.prototype.$getColor = function (dark, color) {
            if (dark) {
                return LightenDarkenColor(color, -150);
            } else {
                return color;
            }
        }

        function applySaturationToHexColor(hex, saturationPercent) {
            if (!/^#([0-9a-f]{6})$/i.test(hex)) {
                throw ('Unexpected color format');
            }

            if (saturationPercent < 0 || saturationPercent > 100) {
                throw ('Unexpected color format');
            }

            var saturationFloat = saturationPercent / 100,
                rgbIntensityFloat = [
                    parseInt(hex.substr(1, 2), 16) / 255,
                    parseInt(hex.substr(3, 2), 16) / 255,
                    parseInt(hex.substr(5, 2), 16) / 255
                ];

            var rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function (a, b) { return a - b; }),
                maxIntensityFloat = rgbIntensityFloatSorted[2],
                mediumIntensityFloat = rgbIntensityFloatSorted[1],
                minIntensityFloat = rgbIntensityFloatSorted[0];

            if (maxIntensityFloat == minIntensityFloat) {
                // All colors have same intensity, which means 
                // the original color is gray, so we can't change saturation.
                return hex;
            }

            // New color max intensity wont change. Lets find medium and weak intensities.
            var newMediumIntensityFloat,
                newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

            if (mediumIntensityFloat == minIntensityFloat) {
                // Weak colors have equal intensity.
                newMediumIntensityFloat = newMinIntensityFloat;
            }
            else {
                // Calculate medium intensity with respect to original intensity proportion.
                var intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
                newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
            }

            var newRgbIntensityFloat = [],
                newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

            // We've found new intensities, but we have then sorted from min to max.
            // Now we have to restore original order.
            rgbIntensityFloat.forEach(function (originalRgb) {
                var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
                newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
            });

            var floatToHex = function (val) { return ('0' + Math.round(val * 255).toString(16)).substr(-2); },
                rgb2hex = function (rgb) { return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]); };

            var newHex = rgb2hex(newRgbIntensityFloat);

            return newHex;
        }

        Vue.prototype.$getPropertyBackgroundColor = function (field) {
            if (field.print) {
                return '';
            }
            else if (field.d && field.bc) {
                return applySaturationToHexColor(field.bc, 30)
            }
            else {
                return !field.d ? field.bc : '';
            }
        }


        Vue.prototype.$highlight = function (value, search, className = 'v-list-item__mask') {
            if (value && search) {
                if (value === search) {
                    return value;
                }

                var replacements = [];
                var tempelem = null;
                var found = search.replace(/\*|%/g, " ").match(/\"(.*?)\"|\S+/g); //eslint-disable-line

                if (found && found.length > 0) {
                    found.forEach(function (item) {
                        // teilt string in Phrasen (getrennt durch Anführungszeichen) und einzelene Wörter

                        (tempelem = item
                            .replace(/\"/g, "") //eslint-disable-line
                            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")), //escape special regex chars
                            "ig";
                        replacements.push(tempelem);
                    });

                    var re = new RegExp(replacements.join("|"), "gi");

                    return value.replace(
                        re,
                        (str) => `<span class='${className}'>${str}</span>`
                    );
                } else {
                    return value;
                }
            } else if (value) {
                return value;
            } else {
                return "";
            }
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        Vue.prototype.$isMailAddress = function (mailAddress) {
            return re.test(String(mailAddress).toLowerCase());
        }


        Vue.prototype.$readUrlParams = function () {

            var urlParams = new URLSearchParams(window.location.search);
            var q, l, f, v, p;
            var sl = urlParams.get("sl"); // is maillink
            q = parseInt(urlParams.get("q"));
            l = parseInt(urlParams.get("l"));
            f = urlParams.get("f");
            v = urlParams.get("v");

            if (sl !== null) {
                let buff = new Buffer.from(sl, 'base64');
                urlParams = new URLSearchParams(buff.toString('ascii'));
                q = parseInt(urlParams.get("q"));
                l = parseInt(urlParams.get("l"));
                if (!f) {
                    f = urlParams.get("f");
                }
                v = parseInt(urlParams.get("v"));
                p = parseInt(urlParams.get("p"));
            }

            this.$setUrlParams({
                q: q,
                l: l,
                f: f,
                v: v,
                p: p
            })

            return {
                q: q,
                l: l,
                f: f,
                v: v,
                p: p
            };

        }

        Vue.prototype.$niceBytes = function (a) {
            let b = 0,
                c = parseInt(a, 10) || 0;
            for (; 1024 <= c && ++b;) c /= 1024;
            return (
                c.toFixed(10 > c && 0 < b ? 1 : 0) +
                " " +
                ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][b]
            );
        }

        Vue.prototype.$mksLabel = function (field, alternatelayout) {

            if (alternatelayout) {
                if (field.hint && field.hint !== '') {

                    return "<div style='float:left;'>" + field.a + (field.r && !field.print ? ' *&nbsp;' : '') + "</div>" +
                        "<div style='float:right;'>" + field.hint + "</div>";
                }
                else {
                    return field.a + (field.r && !field.print ? ' *' : '');
                }
            } else {
                return null;
            }
        }
    }
}