import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(`${Vue.prototype.$http.defaults.baseURL}changes-hub`)
      .configureLogging(LogLevel.Information)
      .build()

    let startedPromise = null
    function start() {
      startedPromise = connection.start().catch(() => { //err => {
        //alert('Failed to connect with hub: ' + err)
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 60000))
      })
      return startedPromise
    }
    connection.onclose(() => start())

    // use new Vue instance as an event bus
    const changesHub = new Vue()
    // every component will use this.$changesHub to access the event bus
    Vue.prototype.$changesHub = changesHub
    // Forward server side SignalR events through $changesHub, where components will listen to them

    connection.on('Changes', (params) => {
      changesHub.$emit('Changes', { params })
    })

    changesHub.joinHubGroup = (grpname) => {
      return startedPromise
        .then(() => connection.invoke('JoinHubGroup', grpname))
        .catch()
    }
    changesHub.leaveHubGroup = (grpname) => {
      return startedPromise
        .then(() => connection.invoke('LeaveHubGroup', grpname))
        .catch()
    }

        
    changesHub.joinDetailsGroup = (detailsId) => {
      return startedPromise
        .then(() => connection.invoke('JoinDetailsGroup', detailsId))
        .catch()
    }
    changesHub.leaveDetailsGroup = (detailsId) => {
      return startedPromise
        .then(() => connection.invoke('LeaveDetailsGroup', detailsId))
        .catch()
    }

    changesHub.detailsChanged = (detailsId) => {
      return startedPromise
        .then(() => connection.invoke('DetailsChanged', detailsId))
        .catch()
    }

    start()
  }
}
