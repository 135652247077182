import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import prototypes from './plugins/prototypes';
import changesHub from './plugins/changes-hub';
import { store } from "@/components/store.js";
import mksresourcecalendar from "@/components/mksresourcecalendar.vue";
import mksexpandingsearchfield from "@/components/mksexpandingsearchfield.vue";
import mksresourcebundlebooked from "@/components/mksresourcebundle_booked.vue";

import { Plugin } from "vue-fragment";
Vue.use(Plugin)

const request = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  timeout: 20000,
  headers: {}
})

request.interceptors.request.use(function (config) {
  if (config.url.indexOf('api/data') == 0 || config.url.indexOf('api/detail') == 0) {
    sessionStorage.setItem("reqconfig", JSON.stringify(config));
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

request.interceptors.response.use(response => {
  // ...
  if (response.request && response.request.responseURL && response.request.responseURL.indexOf("/openid-connect/auth?") > -1) {
    window.location = response.request.responseURL;// + (response.config.url.indexOf("redirect_uri") === 0 ? "&redirect_uri=https%3A%2F%2Fdb.myown.local" : "");
  }
  else {
    return response
  }
}, err => {

  var config = (sessionStorage.getItem("reqconfig") ? JSON.parse(sessionStorage.getItem("reqconfig")) : null);
  var newurlparam = ""
  if (window.location.search != "" && window.location.search.indexOf('?state=') !== 0) {
    newurlparam = window.location.search;
  }
  else if (config && config.data) {
    var parts = [];
    var base = config.data.query ? config.data.query : config.data;
    if (base.id_query) { parts.push("q=" + base.id_query); }
    if (base.id_link) { parts.push("l=" + base.id_link); }
    if (base.id) { parts.push("v=" + base.id); }
    if (parts.length > 0) {
      newurlparam = "?" + parts.join('&');
    }
  }
  if (err.message === 'Network Error') {
    if (document.referrer !== "") {
      // Die Seite wurde über einen Link aufgerufen
      window.location = "/Account/Login/" + newurlparam;
    } else {
      // Die Seite wurde direkt aufgerufen (z.B. durch Eingabe der URL oder Lesezeichen)
      window.location = "/login.html";
    }
  }
  else if (err.response.status === 403)
  {
    window.location = "/notallowed.html";
  }
  else {
    return Promise.reject(err)
  }
})

// export default request;


// Vue.config.productionTip = false
// // Setup axios as the Vue default $http library
// axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
// Vue.prototype.$http = request
// Vue.use(prototypes)
// Vue.use(changesHub)
// Vue.component('mksresourcecalendar', mksresourcecalendar)
// Vue.component('mksresourcebundlebooked', mksresourcebundlebooked)
// Vue.component('mksexpandingsearchfield', mksexpandingsearchfield)

// var mv = new Vue({
//   vuetify,
//   store,
//   render: h => h(App),
// })

// mv.$mount('#app')

// export default request;

Vue.config.productionTip = false
// Setup axios as the Vue default $http library
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
Vue.prototype.$http = request
Vue.use(prototypes)

var retryCounter = 0;
var retryCounterInterval = null;
function setRetryCounter() {
  var elem = document.getElementById("retryCounterSpn");
  if (elem) {
    elem.innerHTML = retryCounter + "s";
  }
  retryCounter -= 1;
}


function getUserFirstThenRunVueApp() {
  retryCounter = 30;

  request
    .get("api/userRefresh")
    .then(response => {
      if (response.data.error) {
        this.heading = "Error";
      } else {

        Vue.use(changesHub)
        Vue.component('mksresourcecalendar', mksresourcecalendar)
        Vue.component('mksresourcebundlebooked', mksresourcebundlebooked)
        Vue.component('mksexpandingsearchfield', mksexpandingsearchfield)

        var mv = new Vue({
          vuetify,
          store,
          render: h => h(App),
        })

        const currentUser = response.data
        currentUser.prefs = mv.$xmlToJson((new DOMParser()).parseFromString(currentUser.prefs, "text/xml")).Prefs

        mv.$store.dispatch("setUser", currentUser).then(
          mv.$mount('#app')
        );

      }
    })
    .catch(e => {
      if (e.response) {
        var message = e.response.data && e.response.data != "" ? e.response.data : e.response.statusText
        if (message === "retryconnect") {
          // var elem = document.getElementById("initialMessage");
          // if (elem) {
          //   elem.remove();
          // }
          // elem = document.getElementById("reconnectMessage");
          // if (elem) {
          //   elem.remove();
          // }
          // elem = document.getElementById("app");
          document.getElementById("app").innerHTML = "<div id='reconnectMessage' style='position: absolute; top: 50%; margin-top: -100px; left: 10%;  right: 10%; font-family:Arial;border:double #998832;" +
            "vertical-align: middle; text-align:center;padding:10px 30px 30px 30px;'><h2>Contacting the database server</h2>Retrying in <span id='retryCounterSpn'></span>. Please be patient...</div>";

          if (retryCounterInterval == null) {
            retryCounterInterval = window.setInterval(setRetryCounter, 1000);
          }
          retryCounter = 30;
          window.setTimeout(getUserFirstThenRunVueApp, 30000);
        }
        else {
          document.getElementById("app").innerHTML = "<div id='errorMessage' style='position: absolute; top: 50%; margin-top: -100px; left: 10%;  right: 10%; font-family:Arial;border:double red;" +
            "vertical-align: middle; text-align:center;padding:10px 30px 30px 30px;'><h2>Error</h2>" + message + "</div>";
        }
      }
    });
}

getUserFirstThenRunVueApp();
