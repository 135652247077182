<template>
  <v-app id="homedb" @mousemove.native="mousemove">
    <v-app-bar app flat color="primary" clipped-left>
      <!-- <v-app-bar-nav-icon color="black" v-if="!drawer" @click.stop="drawer = !drawer" /> -->

      <img
        height="36px"
        src="@/assets/Icon_black_colour.png"
        alt="Logo"
      /><v-spacer></v-spacer>
      <v-toolbar-title style="color: black">{{ heading }} </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu bottom left close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon style="color: black">logout</v-icon>
          </v-btn>
        </template>

        <v-list nav>
          <v-list-item>
            <v-list-item @click="logout()">LOG OUT</v-list-item>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="false" icon @click="showhelp = true">
        <v-icon style="color: black">help_outline</v-icon>
      </v-btn>
      <mkshelp :visible="showhelp" @close="closeHelp"></mkshelp>
      <v-dialog
        v-model="showlogout"
        content-class="v-dialog-mks"
        no-click-animation
        persistent
      >
        <v-card>
          <v-card-title>
            <div style="text-align: center; width: 100%">Logout in process</div>
          </v-card-title>
          <v-card-text
            ><v-sheet>
              <div style="text-align: center; width: 100%">
                <img
                  src="@/assets/Logout.png"
                  alt="logout"
                  style="opacity: 0.1"
                />
              </div>
            </v-sheet> </v-card-text></v-card
      ></v-dialog>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      clipped
      :permanent="true"
      :temporary="false"
    >
      <template v-for="(item, index) in naventries">
        <v-list
          nav
          :key="'nav_' + item.id"
          :style="index > 0 ? 'padding-top: 0px;' : ''"
        >
          <v-list-group
            :key="item.id"
            v-show="item.children.length > 0"
            v-model="navgroup[index]"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item-group class="pl-10" v-model="selectedNavEntry">
              <template v-for="subItem in item.children">
                <v-tooltip
                  v-if="subItem.tooltip"
                  open-delay="500"
                  bottom
                  :key="subItem.parentId + ' ' + subItem.id"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      :value="subItem.id"
                      @click="handleClick(subItem)"
                      :disabled="subItem.disabled"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content style="margin-left: 1em">
                        <v-list-item-title
                          v-text="subItem.title"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon :class="subItem.disabled ? 'grey--text' : ''">{{
                          subItem.icon
                        }}</v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </template>
                  <span>{{ subItem.tooltip }}</span>
                </v-tooltip>
                <v-list-item v-else :key="subItem.parentId + ' ' + subItem.id"
                  link :value="subItem.id" @click="handleClick(subItem)"
                  :disabled="subItem.disabled" >
                  <v-list-item-content style="margin-left: 1em">
                    <v-list-item-title
                      v-text="subItem.title"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon :class="subItem.disabled ? 'grey--text' : ''">{{
                      subItem.icon
                    }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </template>

      <template v-slot:append>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-tooltip
                  top
                  v-if="
                    dataprivacyurl &&
                    dataprivacyurl !== null &&
                    dataprivacyurl !== ''
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-img
                      max-width="24"
                      max-height="24"
                      v-bind="attrs"
                      v-on="on"
                      @click="opendataprivacy"
                      src="@/assets/dataprivacy.png"
                      alt="privacy information"
                    ></v-img>
                  </template>
                  <span>Open data privacy information</span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip
                  top
                  v-if="
                    termsandconditionsurl &&
                    termsandconditionsurl !== null &&
                    termsandconditionsurl !== ''
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      @click="opentermsandconditions"
                      >edit_note</v-icon
                    >
                  </template>
                  <span>Open terms &amp; conditions information</span>
                </v-tooltip>
              </v-col>
              <v-col style="text-align: right"> Vers. 0.9 </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>
    <v-main :style="mainStyle">
      <mksresourcelist
        v-if="lastNavgroup == 0"
        :selectedNavEntry="lastClicked"
      ></mksresourcelist>
      <mksresourceuserview
        v-if="lastNavgroup == 1"
        :selectedNavEntry="lastClicked"
      ></mksresourceuserview>
    </v-main>

    <Snackbar></Snackbar>
    <v-snackbar v-model="showRefresh" absolute bottom right timeout="60000000">
      <v-layout row>
        <v-layout column align-end>
          A new Web Interface Version is available.
          <p>Click <strong>Refresh</strong> to update.</p>
        </v-layout></v-layout
      >

      <v-layout row>
        <v-layout column align-end>
          <v-btn class="accent" @click="refreshApp">Refresh</v-btn>
        </v-layout></v-layout
      >
    </v-snackbar>
  </v-app>
</template>

<style scoped>
div.v-input.expanding-search {
  max-width: 20px;
}
</style>
<style>
.v-dialog-mks {
  width: unset;
}
</style>

<script>
import Snackbar from "@/components/Snackbar.vue";
import mkshelp from "@/components/mkshelp.vue";
import mksresourcelist from "@/components/mksresourcelist.vue";
import mksresourceuserview from "@/components/mksresourceuserview.vue";

import { mapState } from "vuex";

export default {
  components: {
    Snackbar,
    mkshelp,
    mksresourcelist,
    mksresourceuserview,
  },

  data: () => ({
    lastClicked: null,
    lastNavgroup: null,
    clientX: 0,
    clientY: 0,
    printlink: {},
    drawer: null,
    showRefresh: false,
    showhelp: false,
    showlogout: false,
    isLoading: false,
    querycombo: null,
    search: null,
    showMenu: false,
    dragging: false,
    AlwaysHideListSelector: false,
    navgroup: [],
    navgroupOld: [],
    naventries: [],
    selectedNavEntry: 0,
    navigation: 0,
    id_query: 0,
    dataprivacyurl: null,
    termsandconditionsurl: null,
  }),
  created() {
    this.switchDarkMode();

    this.$store.dispatch("setAppheading", process.env.VUE_APP_TITLE);

    // this.$changesHub.joinHubGroup("U" + this.currentUser.account);

    // this.$changesHub.$on("Changes", this.RefreshUser);
  },

  computed: {
    ...mapState([
      "currentUser",
      "currenthubsubscriptions",
      "appheading",
      "lastKeyUp",
    ]),

    heading() {
      return this.appheading;
    },

    mainStyle() {
      return "height: 100px; overflow: auto;";
    },

    prefs() {
      return this.currentUser.prefs;
    },

    lastkeyEvent() {
      return this.keyEvent;
    },

    drawerPermanent() {
      return !this.AlwaysHideListSelector && this.$vuetify.breakpoint.lgAndUp;
    },
    drawerTemporary() {
      return this.AlwaysHideListSelector || this.$vuetify.breakpoint.mdAndDown;
    },
  },

  methods: {
    handleClick(listitem) {
      this.lastClicked = listitem;
      this.lastNavgroup = this.naventries.findIndex((obj) =>
        obj.children.some((child) => child.id === listitem.id)
      );
    },
    logout() {
      this.showlogout = true;
      this.$nextTick(() => {
        sessionStorage.removeItem("reqconfig");
        window.location.href = "/Account/Logout";
        // this.$router.push("/Account/Logout");
      });
    },
    closeHelp() {
      this.showhelp = false;
    },
    switchDarkMode: function () {
      this.$vuetify.theme.dark = true;
      // this.$getPref({
      //   TabName: "Options",
      //   PrefID: "DarkMode",
      // });
    },

    mousemove: function (evt) {
      this.clientX = evt.clientX;
      this.clientY = evt.clientY;
    },

    async RefreshUser(params) {
      if (params && params["params"] && params["params"].grpId === "User") {
        switch (params["params"].what) {
          case "refresh user":
            var tempthis = this;
            await tempthis.$refreshUser().then(() => {
              tempthis.id_query = 1;
              // tempthis.changeQuery(tempthis.id_query);
            });
            break;
          case "refresh version":
            this.showRefresh = true;
            break;
        }
      }
    },

    refreshApp() {
      window.location.reload();
    },

    opendataprivacy() {
      window.open(this.dataprivacyurl, "_blank");
    },
    opentermsandconditions() {
      window.open(this.termsandconditionsurl, "_blank");
    },

    closeDrawer() {
      this.drawer = false;

      this.$nextTick(() => {
        this.$el.querySelector("input[fi]").focus();
      });
    },

    async storePrefAndRefresh(tabName, prefID, value, save) {
      let tempthis = this;

      await this.$setPref({
        TabName: tabName,
        PrefID: prefID,
        Value: value,
        Save: save,
      })
        .then(() => {
          return this.$refreshUser();
        })
        .then(() => {
          tempthis.querycombo = null;
        });
    },

    storePref(tabName, prefID, value, save) {
      //, refreshNav = true) {

      this.$setPref({
        TabName: tabName,
        PrefID: prefID,
        Value: value,
        Save: save,
      });
    },
  },
  mounted() {
    this._evtListener = function (e) {
      if (e.type === "keyup" && e.target.placeholder !== "List Name") {
        this.$store.dispatch("setLastKeyUp", e);
      }
    };

    document.addEventListener("keyup", this._evtListener.bind(this));

    this.$http
      .get("api/Navigation")
      .then((response) => {
        if (typeof response.data === "string") {
          throw "malformatted JSON Data";
        }

        if (response.data.error) {
          throw response.data.error;
        } else {
          this.naventries = response.data.children;
          if (this.naventries && this.naventries.length > 0) {
            this.selectedNavEntry = this.naventries[0].children[0].id;
            this.lastClicked = this.naventries[0].children[0];
            this.navgroup[0] = true;
            this.navgroup[1] = true;
            this.lastNavgroup = 0;
          }
        }
      })
      .finally(() => {
        if (this.dataprivacyurl == null) {
          this.dataprivacyurl = this.currentUser.dataprivacyurl;
        }
        if (this.termsandconditionsurl == null) {
          this.termsandconditionsurl = this.currentUser.termsandconditionsurl;
        }
      });
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this._evtListener);
  },
  watch: {
    lastKeyUp: {
      handler() {
        if (this.lastKeyUp.altKey) {
          switch (this.lastKeyUp.keyCode) {
            case 88: // alt + x --> open navigation drawer
              this.drawer = true;
              break;
          }
        } else {
          switch (this.lastKeyUp.keyCode) {
            case 27:
              // nested dialogs richtig schließen
              // Button ermitteln, der im zu oberst liegenden Dialog für das Schließen zuständig ist und diesen anklicken
              var closebtn = this.$getHighest(".v-dialog--active");
              if (closebtn != null) {
                closebtn = closebtn.getElementsByClassName("mdi-close");
                if (closebtn.length > 0) {
                  closebtn[0].click();
                }
              }
              this.drawer = false;

              break;
          }
        }
      },
    },
  },
};
</script>
