var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{staticStyle:{"position":"relative"},attrs:{"scrollable":"","retain-focus":true,"persistent":"","no-click-animation":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{ref:"movingText",attrs:{"id":"movingText"}},[_vm._v(_vm._s(_vm.tmpTime))]),_c('v-card',{style:(_vm.dlgStyle)},[_c('v-card-title',{class:_vm.headlineclass,staticStyle:{"position":"relative"}},[_c('span',{staticClass:"mksheadline",attrs:{"dialogid":_vm.$el ? _vm.$el.__vue__._uid : ''}},[_vm._v(" Bookings ")]),_c('v-spacer'),_c('v-icon',{staticClass:"pnt",on:{"click":function($event){_vm.show = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',{ref:"outer"},[_c('v-container',{ref:"inner"},[[_c('div',{ref:"jumpdummytop"})],(_vm.showLoadingCircle && !_vm.resetting)?[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)]:[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{ref:"bundleTitle",attrs:{"outlined":"","dense":"","label":"Bundle title","flat":"","hide-details":"","readonly":""},model:{value:(_vm.bundle.details.bundle_title),callback:function ($$v) {_vm.$set(_vm.bundle.details, "bundle_title", $$v)},expression:"bundle.details.bundle_title"}})],1),(!_vm.bundle.details.interchangeable)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"700","close-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-select',_vm._g({attrs:{"items":_vm.bookingperiods,"item-text":"t","item-value":"v","dense":"","outlined":"","hide-details":"","label":"Smallest period","readonly":""},model:{value:(
                        _vm.bundle.details
                          .smallest_possible_booking_period_in_minutes
                      ),callback:function ($$v) {_vm.$set(_vm.bundle.details
                          , "smallest_possible_booking_period_in_minutes", $$v)},expression:"\n                        bundle.details\n                          .smallest_possible_booking_period_in_minutes\n                      "}},on))]}}],null,false,2812508653)},[_c('span',[_vm._v("smallest possible booking period in hours and minutes")])])],1):_vm._e(),(!_vm.bundle.details.interchangeable)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"700","close-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-select',_vm._g({attrs:{"items":_vm.setupperiods,"item-text":"t","item-value":"v","dense":"","outlined":"","hide-details":"","label":"Setup","readonly":""},model:{value:(_vm.bundle.details.setup_time_in_minutes),callback:function ($$v) {_vm.$set(_vm.bundle.details, "setup_time_in_minutes", $$v)},expression:"bundle.details.setup_time_in_minutes"}},on))]}}],null,false,952218640)},[_c('span',[_vm._v("Setup time in hours and minutes")])])],1):_vm._e(),(!_vm.bundle.details.interchangeable)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"700","close-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-select',_vm._g({attrs:{"items":_vm.setupperiods,"item-text":"t","item-value":"v","dense":"","outlined":"","hide-details":"","label":"Teardown","readonly":""},model:{value:(_vm.bundle.details.teardown_time_in_minutes),callback:function ($$v) {_vm.$set(_vm.bundle.details, "teardown_time_in_minutes", $$v)},expression:"bundle.details.teardown_time_in_minutes"}},on))]}}],null,false,2320126608)},[_c('span',[_vm._v("Teardown time in hours and minutes")])])],1):_vm._e(),(!_vm.bundle.details.interchangeable)?_c('v-col',{style:('padding: 7px;' +
                  (_vm.bundle.details
                    .smallest_possible_booking_period_in_minutes -
                    _vm.bundle.details.setup_time_in_minutes -
                    _vm.bundle.details.teardown_time_in_minutes <
                  0
                    ? 'color:red;'
                    : '')),attrs:{"cols":"1"}},[_vm._v(" = "+_vm._s(_vm.bundle.details.smallest_possible_booking_period_in_minutes - _vm.bundle.details.setup_time_in_minutes - _vm.bundle.details.teardown_time_in_minutes)+" min "),_c('br'),_c('span',{staticStyle:{"font-size":"smaller"}},[_vm._v("effective usage")])]):_vm._e()],1),_c('v-row',[_c('v-col',[_c('mksresourcecalendar',{ref:"calendar",staticStyle:{"height":"600px"},attrs:{"bundle":_vm.bundle,"calendarSettings":_vm.calendarSettings},on:{"closeReservation":function($event){return _vm.getDetails()}}})],1)],1)]],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }