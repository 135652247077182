import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    appheading: null,
    snackbarContent: '',
    snackbarColor: '',
    currentDialog: null,
    openDialogs: [],
    selectedItems: [],
    lastKeyUp: null,
    urlParams: null,
    previewdata: {}
  },

  getters: {
    getCurrentDialog: state => {
      return state.currentDialog;
    },

    getUrlParams: state => {
      return state.urlParams;
    },

    getSelectedItems: (state) => (id_query) => {
      return state.selectedItems.filter(item => item.id_query === id_query && item.isSelected).map(itemY => { return itemY.id; });
    },

  },
  mutations: {
    setUser: (state, newValue) => {
      state.currentUser = newValue
    },
    
    setUrlParams: (state, newValue) => {
      state.urlParams = newValue
    },

    setCurrentDialog: (state, newValue) => {
      state.currentDialog = newValue
    },

    snackbarShowMessage(state, payload) {
      state.snackbarContent = payload.content
      state.snackbarColor = payload.color
      state.timeout = payload.timeout
    },
    
    setpreviewdata(state, payload) {
      state.previewdata = payload
    },

    setLastKeyUp: (state, newValue) => {
      state.lastKeyUp = newValue
    },

    setSelectedItems: (state, newValue) => {
      if (!newValue.isSelected)
      {
        state.selectedItems = state.selectedItems.filter(item => !(item.id_query === newValue.id_query && item.id === newValue.id))
      }
      else
      {
        state.selectedItems.push(newValue)
      }
    },

    setAppheading: (state, newValue) => {
      state.appheading = newValue
    },    

  },
  actions: {
    setUser: ({ commit }, newValue) => {
      commit('setUser', newValue)
    },
    
    setUrlParams: ({ commit }, newValue) => {
      commit('setUrlParams', newValue)
    },

    setCurrentDialog: ({ commit }, newValue) => {
      commit('setCurrentDialog', newValue)
    },

    setAppheading: ({ commit }, newValue) => {
      commit('setAppheading', newValue)
    },

    setLastKeyUp: ({ commit }, newValue) => {
      commit('setLastKeyUp', newValue)
    },
  }

})