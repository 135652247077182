import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

var mytheme_light = {};
var mytheme_dark = {};

if (process.env.VUE_APP_COLOR_THEME_LIGHT) {
    mytheme_light = JSON.parse(process.env.VUE_APP_COLOR_THEME_LIGHT)
}
if (process.env.VUE_APP_COLOR_THEME_LIGHT) {
    mytheme_dark = JSON.parse(process.env.VUE_APP_COLOR_THEME_DARK)
}
export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            light: mytheme_light,
            dark: mytheme_dark,
        },
    },
})
